<template>
  <div>
    <!-- pc端 -->
    <div class="_w-max _pc-pad-y-75 _mb-pad-y-55 _pc-pad-x-265 _flex-row _flex-row-bet _mb-display-none">
      <div class="_pc-mar-right-50 _pc-pad-right-40 left-box _pos-rel">
        <!--banner-->
        <div class="left-header _w-max _border-bottom _pc-pad-bottom-30 _mb-pad-bottom-10 _flex-row" v-for="(item,index) in is_banner"  :key="index">
          <div class="_pc-mar-right-35" style="cursor: pointer;"><img :src="item.image_input" alt="" class="_pc-w-340"></div>
          <div class="_w-max">
            <div class="_pc-font-23 _pc-mar-bottom-25 _mb-mar-bottom-5" style="font-weight: bolder;"> {{ item.title }}</div>
            <div class="_pc-font-14 _pc-mar-bottom-35 _mb-mar-bottom-15 banner_synopsis">{{item.synopsis}}</div>
            <div class="_flex-row _flex-row-bet _flex-row-mid" style="color: #666">
              <div class="_flex-row _flex-row-mid">
                <img class="_pc-w-15" src="@/assets/image/icon-clock.png" alt="">
                <div class="_pc-mar-left-10 _pc-font-16">{{ item.add_time }}</div>
              </div>
              <router-link :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(2)}}" class="_btn-p-hover _pc-w-160 _pc-h-40 _mb-h-20 _flex-row _flex-row-center  _flex-row-mid _pc-font-18" style="border:1px solid var(--theme-color);font-weight: 700;cursor: pointer;">
                查看详情
               <img
              class="_pc-w-20 _pc-mar-left-10 right-ico-02"
              src="@/assets/image/right-ico-03.png"
              alt=""
            />

            <img
              class="_pc-w-20 _pc-mar-left-10 right-ico-hover"
              src="@/assets/image/right-ico-02.png"
              alt=""
            />
              </router-link>
            </div>
          </div>
        </div>
        <!--普通资讯-->
        <div class="left-content ">
          <div v-for="(item,index) in data.list" :key="index" class="_pc-w-max _pc-pad-y-40 _mb-pad-y-20 _border-top _flex-row-mid">
            <div class="_pc-w-350 _flex-row-center"><img :src="item.image_input" alt="" class="_w-max"></div>
            <div class="_pc-mar-left-30 _w-max">
              <div class="_pc-font-20 _b">{{ item.title }}</div>
              <div class="_pc-font-14 _pc-mar-top-20 _mb-mar-top-5 _flex-wrap _over-hid syno-box"  style="" >{{item.synopsis}}</div>
              <div class="_pc-mar-top-20 _mb-mar-top-5 _flex-row-bet">
                <div class="_pc-font-14 _flex-row _flex-row-mid" style="color: #666;">
                  <img class="_pc-w-15" src="@/assets/image/icon-clock.png" alt="" />
                  <div class="_pc-mar-left-10 _mb-mar-top-5">{{ item.add_time }}</div>
                </div>
                <router-link :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(3)}}">
                  <img src="@/assets/image/right-doc-arrow.png" alt="" class="_pc-w-40" style="cursor: pointer;"/>
                </router-link >
              </div>
            </div>
          </div>
          <!--分页-->
          <div class="_pc-w-max _flex-row _flex-row-mid _pos-abs-bottom _pc-mar-right-50 _right" style="float: right;">
            <button @click="prePage" class="_pc-h-25 _border-radius-25 _pc-pad-x-20 _pc-mar-right-45 _white theme-color-bg theme-color-border _pc-font-16" style="cursor: pointer;">
              上一页
            </button>
            <div :class="page.currentPage==item?point_page:'_pc-mar-x-15'" v-for="(item,index) in page.pageNum" :key="index" @click="getPageList(item)" style="cursor: pointer;">
              {{ item }}
            </div>
            <button @click="nextPage" class="_pc-h-25 _border-radius-25 _pc-pad-x-20 _pc-mar-left-45 _white theme-color-bg theme-color-border _pc-font-16" style="cursor: pointer;">
              下一页
            </button>
          </div>
        </div>
      </div>
      <!--热门咨询-->
      <div class="right-box">
        <router-link :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(1)}}" class="_flex-col _pc-mar-bottom-100 _mb-mar-bottom-40" v-for="(item,index) in is_hot"  :key="index" v-show="index<4">
          <img :src="item.image_input" alt="" class="_pc-w-370 _pc-h-220 _mb-h-60" style="width: auto;">
          <div class="_pc-mar-top-35 _b _pc-font-20 _mb-mar-top-15">{{ item.title }}</div>
        </router-link>
      </div>
    </div>


    <!-- 手机端 -->
    <div class="_pc-display-none _mb-pad-x-30 _mb-pad-bottom-60 _mb-pad-top-30">
      <router-link router-link :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(3)}}" class="_mb-pad-y-30 _flex-row " :class="index==data.list.length-1?'':'_border-bottom'" v-for="(item,index) in data.list" :key="item.id">
        <img class=" _pos-rel _z-index-1 _mb-w-260" :src="item.image_input[0]" />
        <div class="_w-max _flex-1 _mb-flex-col-bet _mb-mar-left-20">
          <div class="_flex-row-bet-mid _mb-mar-bottom-0">
            <div class=" _mb-font-28 _black _b _line-1">{{ item.title }}</div>
            <div class="_grey-99  _mb-display-none" style="align-self: flex-end" >{{ item.add_time }}</div>
          </div>
          <div class="_grey-99  _mb-font-22 _line-3" style="line-height: 1.5">{{ item.synopsis }}</div>

          <div class="_grey-99 _mb-font-22 _flex-row-mid" style="line-height: 1.5">
            <img class="_mb-w-20 _mb-mar-right-10" src="@/assets/image/icon-clock.png" alt="">
            <div>{{ item.add_time }}</div>
          </div>
        </div>
      </router-link>

      <div class=" _flex-row-center-mid _pc-display-none">
        <button @click="prePage" class="btn _white-bg theme-color _mb-pad-x-40  _mb-pad-y-20  _mb-mar-right-50 _flex-row-center-mid  _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;" >
          <img  class="_mb-w-30 _mb-mar-right-10" src="@/assets/image/right-ico-02.png" style="transform: rotateY(180deg)" alt=""/>上一个
        </button>
        <button @click="nextPage" class="btn _white-bg _mb-pad-x-40 theme-color  _mb-pad-y-20  _mb-mar-right-30 _flex-row-center-mid  _mb-font-22" style="letter-spacing: 1.5px; cursor: pointer;">
          下一个<img class=" _mb-w-30 _mb-mar-left-10" src="@/assets/image/right-ico-02.png" alt=""/>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PcWebIndex',
  data() {
    return {
      //热门
      is_hot:[],
      //banner
      is_banner:[],
      data:{},
      page:{
        pageNum:1,
        pageSize:5,//每页条数
        currentPage:1 //页码
      },
      point_page:'point_page _pc-w-25 _pc-h-25 _pc-mar-x-10 _pc-font-18'
    };
  },
  mounted() {
    this.get_data()
  },
  created() {},
  methods: {
    async get_data(){
      let that = this;
      let data = {
        page:this.page.currentPage,
        limit:this.page.pageSize,
        cid:3
      }
      await this.$axios.get(
          'site/get_site_newList',
        {params:data}
        ).then(res => {
        this.data=res.data.data
        this.is_banner=[];
        this.is_hot=[];
        for (let i = 0;i<this.data.all.length;i++){
          if (this.data.all[i].is_banner==1){
            this.is_banner.push(this.data.all[i]);
          }
          if (this.data.all[i].is_hot==1){
            this.is_hot.push(this.data.all[i]);
          }
        };
        this.pageNum1();
      })
    },
    //  页数
    pageNum1(){
      let page = Math.ceil(this.data.count / this.page.pageSize) || 1;
      this.page.pageNum = page;
    },
    getPageList(index){
      this.page.currentPage = index;
      this.get_data()
    },
    nextPage(){
      if(this.page.currentPage===this.page.pageNum){
        return ''
      }
      this.page.currentPage = ++this.page.currentPage;
      this.get_data()
    },
    prePage(){
      if(this.page.currentPage===1){
        return ''
      }
      this.page.currentPage = --this.page.currentPage;
      this.get_data()
    }
  },
};
</script>

<style scoped>
.left-box {
  width: 70%;
  position: relative;
}
.left-box::after {
  content: '';
  height: 93%;
  width: 1px;
  background-color: #999;
  position: absolute;
  right: 0;
  top: 0;
}
.right-box {
  width: 30%;
}
.point_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background-color: var(--theme-color-bg);
  color: #FFF;
  border-radius: 50%;
}
.banner_synopsis{
  color: #666;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.6;
}

.syno-box{
  color: #666; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.6;
}
.btn{
  border:1px solid var(--theme-color-bg);
}
._btn-p-hover:hover {background: var(--theme-color-bg);color: #FFF;}
._btn-p-hover{color: var(--theme-color-title);}
.right-ico-hover{display: block;}
.right-ico-02{display: none;}
._btn-p-hover:hover .right-ico-02{display: block;}
._btn-p-hover:hover .right-ico-hover{display: none;}
@media screen and (max-device-width: 768px) {
  .point_page{
    height: 0.88052vh;
    width: 0.88052vh;
  }
}
</style>
